import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "concepts"
    }}>{`Concepts`}</h1>
    <h2 {...{
      "id": "about-the-zero-sum-game-ocelot-plays"
    }}>{`About the zero-sum game Ocelot plays`}</h2>
    <p>{`The internal architecture of Ocelot originates from ideas rooted in a field known in the literature as `}<em parentName="p">{`Game Theory`}</em>{`.
Game theory is the study of mathematical models of strategic interactions among rational agents. It has applications
in all fields of social science, as well as in logic, systems science, and, as in our case, in computer science.`}</p>
    <p>{`Specifically, the game set by Ocelot is known as a `}<em parentName="p">{`Zero-Sum`}</em>{` game.`}</p>
    <p>{`In a zero-sum game, adding up the participants' total gain and subtracting their total losses, they will sum to zero.`}</p>
    <p>{`In the case of Ocelot, we have two participants:`}</p>
    <ul>
      <li parentName="ul">{`On the one hand, the operator setting the security policy for a specific operation; and`}</li>
      <li parentName="ul">{`On the other hand, the user who invokes that same operation.`}</li>
    </ul>
    <p>{`As mentioned in other sections, we define an Operator as a specific person who is allowed to operate Ocelot and modify/visualize
the current security policy.`}</p>
    <p>{`The game unfolds through a classic architectural style called `}<em parentName="p">{`Pipes and Filters`}</em>{`.`}</p>
    <p>{`In the following sections, we will introduce generically this architectural style, and we will then dive into the
Ocelot's filters.`}</p>
    <h2 {...{
      "id": "ocelots-pipes-and-filters"
    }}>{`Ocelot's Pipes and Filters`}</h2>
    <p>{`Quoting `}<a parentName="p" {...{
        "href": "https://www.enterpriseintegrationpatterns.com/PipesAndFilters.html"
      }}><em parentName="a">{`"Enterprise Integration Patterns"`}</em></a>{` from
Gregor Hohpe and Bobby Woolf:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Use the `}<em parentName="p">{`Pipes and Filters`}</em>{` architectural style to divide a larger processing task into a sequence of smaller,
independent processing steps (Filters) that are connected by channels (Pipes).
Each filter exposes a very simple interface: it receives messages on the inbound pipe, processes the message, and
publishes the results to the outbound pipe. The pipe connects one filter to the next, sending output messages from
one filter to the next.`}</p>
    </blockquote>
    <p><img alt="Ocelot's Pipe and Filters" src={require("../drawings/ocelot-pipes-and-filters.svg")} /></p>
    <p>{`As expressed in the picture above, the call generated by the user agent (Step 1 in the diagram above) hits the API Gateway.
The latter forwards the original request to Ocelot to decide whether the call is legit (Step 2).`}</p>
    <p>{`Ocelot analyses the request, passing it through 6 filters in a specific order, and then, if the request is allowed, Ocelot releases a short-lived token that will authorize that specific operation.`}</p>
    <p>{`In the following, we will describe the filters and explain how they work and interact.`}</p>
    <h2 {...{
      "id": "filters"
    }}>{`Filters`}</h2>
    <p>{`From the instant the request hits Ocelot, a new instance of the game starts, seeing both parties, the operator and the
user having equal scores of zero.`}</p>
    <h3 {...{
      "id": "business-resources"
    }}>{`Business Resources`}</h3>
    <p>{`The `}<em parentName="p">{`Business Resources Filter`}</em>{` is responsible for handling the Business Operations.
If a specific request does not match any expected business operation, Ocelot will reject the call with a 404 error (Not Found).`}</p>
    <p>{`Otherwise, the object representing the business operations contains specific demands that make the current
score of the user goes immediately in negative.`}</p>
    <p>{`There are several types of demands that an operator can require through a business resource at this point:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Demand Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Audience`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The list of ids of the business services that are allowed to take part in the current business operation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ACRs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://openid.net/specs/openid-connect-core-1_0.html"
            }}>{`Authentication Context Class References`}</a>{`. A list of strings representing the security level required to operate.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AMRs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://datatracker.ietf.org/doc/html/rfc8176"
            }}>{`Authentication Methods References`}</a>{`. A list of strings representing the technical authentication methods required to operate.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Business Scopes`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of bespoke strings representing business capabilities, roles, and permission that are required to operate.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The following JSON object represents an example of a resource entity:`}</p>
    <p>{`We believe that to do just that, we need to ensure we make it easy for the application developers to enforce the
`}<em parentName="p">{`Principle of Least Privilege`}</em>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Users and programs should only have the necessary privileges to complete their tasks.`}</p>
    </blockquote>
    <p>{`For this reason, you are able through Ocelot to specify which accesses are required for each business operation through
this filter, and those are the ones that will be then part of the token being transferred to the business services.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-JSON"
      }}>{`{
    "id": "0bf7737a-cc91-4d9e-9cd2-c4b5d194b5d0",
    "hostnames": ["mybank.com"],
    "pathPatternRegex": "/api/v1/transfers/**",
    "active": true,
    "priority": 10,
    "requiredAcrs": ["http://ocelot.yanchware.com/acrs/oidc/cbb4d83d-ac66-47e0-b142-35363be03fd9"],
    "requiredAmrs": ["mfa"],
    "requiredBusinessScopes": ["banking-basis"],
    "expectedAudience": ["transfers-service", "account-service"],
}
`}</code></pre>
    <h3 {...{
      "id": "clients"
    }}>{`Clients`}</h3>
    <p>{`The `}<em parentName="p">{`Client Filter`}</em>{` analyzes the request to understand whether the caller uses a known client.`}</p>
    <p>{`If the filter does not find any client, the request is not rejected. Instead, the call proceeds to the next filter without any
changes registered to the two parties' scores.`}</p>
    <p>{`A caller can specify a client using two (configurable) headers, namely `}<inlineCode parentName="p">{`X-ClientId`}</inlineCode>{` and `}<inlineCode parentName="p">{`X-ClientSecret`}</inlineCode>{`
or through certificates. The client filter tries to fetch the client from the repository and checks the credentials against that record. In the case all is good to go, the user receives his/her score:`}</p>
    <ul>
      <li parentName="ul">{`All the AMRs relative to the authentication method used`}</li>
      <li parentName="ul">{`All ACR relative to the security level reached`}</li>
      <li parentName="ul">{`any eventual Business Scopes registered on the client object`}</li>
    </ul>
    <p>{`The following JSON object represents an example of a client entity:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-JSON"
      }}>{`{
    "id": "0bf7737a-cc91-4d9e-9cd2-c4b5d194b5d0",
    "principalId": "android-mobile-app",
    "active": true,
    "grantedAcrs": ["http://ocelot.yanchware.com/acrs/authenticated/"],
    "grantedAmrs": ["pwd"],
    "grantedBusinessScopes": ["mobile"]
}
`}</code></pre>
    <h3 {...{
      "id": "session"
    }}>{`Session`}</h3>
    <p>{`The `}<em parentName="p">{`Session Filter`}</em>{` analyses the request to understand whether the request is part of an existing session.
A request can present a session secret in the Cookie or the Authorization headers.`}</p>
    <p>{`In case the request does not have any reference to a session or the session is either inactive or not found, then a new
anonymous session is associated with the specific call.`}</p>
    <p>{`Instead, if the session is found, the user receives on his/her score all AMRs, ACRs, and Business Scopes that are stored
in the current session.`}</p>
    <h3 {...{
      "id": "users"
    }}>{`Users`}</h3>
    <p>{`The `}<em parentName="p">{`User Filter`}</em>{` analyses the session to understand whether it is associated with a specific user.`}</p>
    <p>{`In case the session does reference a user, or the user is either inactive or not found, the standard anonymous user
is selected and associated with the specific session.`}</p>
    <p>{`Instead, if the user object exists, the user receives on his/her score all Business Scopes associated with his/her
current session. The operator receives all required AMRs and ACRs associated with the user object, instead, as some
users must meet specific security requirements before they are allowed to use the protected feature.`}</p>
    <p>{`The following JSON object represents an example of a user entity:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-JSON"
      }}>{`{
    "id": "0bf7737a-cc91-4d9e-9cd2-c4b5d194b5d0",
    "principalId": "gino@example.com",
    "active": true,
    "requiredAcrs": ["http://ocelot.yanchware.com/acrs/authenticated/"],
    "requiredAmrs": ["pwd"],
    "grantedBusinessScopes": ["private-customer"]
}
`}</code></pre>
    <h3 {...{
      "id": "roles"
    }}>{`Roles`}</h3>
    <p>{`The `}<em parentName="p">{`Role Filter`}</em>{` analyses the request to understand whether any business scopes associated with the session is a role.`}</p>
    <p>{`In case the session does not have any reference to a role or the role is either inactive or not found, then the filter
does not perform any changes to the parties' score.`}</p>
    <p>{`Instead, if the role exists, the user receives on his/her score all Business Scopes stored in the role object.
The operator receives all required AMRs and ACRs present in the role object, as some roles can have specific
security requirements before they can be associated with a specific user or client.`}</p>
    <p>{`The following JSON object represents an example of a role entity:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-JSON"
      }}>{`{
    "id": "0bf7737a-cc91-4d9e-9cd2-c4b5d194b5d0",
    "name": "sales-admin",
    "active": true,
    "requiredAcrs": ["http://ocelot.yanchware.com/acrs/oidc/cbb4d83d-ac66-47e0-b142-35363be03fd9"],
    "requiredAmrs": ["mfa"],
    "grantedBusinessScopes": ["discounts:admin", "customers:edit"]
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      